import React from 'react';
import '../assets/scss/ArtistasDatos.scss';
import Container from "./Container";
import artistasDB from '../../db/artistas.json';
import { Link } from "react-router-dom";
import Name from "./NombreArtistas";

function ArtistasDatos() {
    const artistasOrdenados = Object.values(artistasDB).sort((a, b) => a.nombre.localeCompare(b.nombre));

    return (
        <Container>
            <div className="contenedor-datosartistas">
                {artistasOrdenados.map(artist => (
                    <div key={artist.id} className="datos-datosartistas">
                        <Link to={"/music/artista/" + artist.url}>
                            <div className="blur-load">
                                <img className='imagen-datosartistas' src={artist.img} loading='lazy' alt={artist.nombre} />
                            </div>
                        </Link>
                        <Name name={artist.nombre} className='nombre-datosartistas' length={12} isVerified={artist.verificado} isAutoVerified={artist.autoverificado}>
                        </Name>
                    </div>
                ))}
            </div>
        </Container>
    );
}

export default ArtistasDatos;
