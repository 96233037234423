import React, { useState } from "react";
import './App.scss';
import '../index.scss'
import Home from "../components/Pages/Home";
import Contribuir from "../components/Pages/Contribuir";
import ContrasenaCambio from "../components/Pages/ContrasenaCambio";
import {Redirect, BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import {ThemeContext, themes} from "../api/Theme";

import appFirebase from '../lisentc'
import {getAuth, onAuthStateChanged} from 'firebase/auth'

const auth = getAuth(appFirebase)



function App(){

    const [usuario, setUsuario] = useState(null)

    onAuthStateChanged(auth, (usuarioFirebase)=>{
        if (usuarioFirebase) {
            setUsuario(usuarioFirebase)
        }else{
            setUsuario(null)
        }

    })

    return (
        
        <ThemeContext.Provider value={themes.dark}>

            <>
                <Router>
                    <Switch>
                        <Route path="/" exact>
                            <Redirect to="/music"/>
                            </Route>
                        <Route path="/music" component={Home}/>
                        <Route path="/colaborar" component={Contribuir}/>
                        <Route path="/olvidadizo" component={ContrasenaCambio}/>
                        
                        <Route path="/damiiawa">
                            <Redirect to="/music/artista/damiiawa"/>
                            </Route>

                            
                       
                    </Switch>
                </Router>
            </>
        </ThemeContext.Provider>
    );
}

export default App;