import React, { useEffect, useState } from 'react';
import { getAuth, onAuthStateChanged, signOut} from 'firebase/auth';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import VerificadoIMG from '../assets/img/Lisent_Verificado.svg'
import ExperimentadorIMG from '../assets/insignias/Experimentador.svg'
import PersonalIMG from '../assets/insignias/Personal.svg'
import '../assets/scss/PerfilOptions.scss'
import Container from "./Container";
import { Link } from 'react-router-dom';
const auth = getAuth();
const db = getFirestore();

function PerfilOptions() {
    const [usuario, setUsuario] = useState(null);
    const [nombreUsuario, setNombreUsuario] = useState('');
    const [verificado, setVerificado] = useState(false);
    const [betatester, setBetatester] = useState(false);
    const [personal, setPersonal] = useState(false);
    const [comentario, setComentario] = useState(false);
    const [artista, setArtista] = useState(false);
    const [url, setURL] = useState(false);
    const [img, setIMG] = useState(false);
  
    useEffect(() => {
      const unsubscribe = onAuthStateChanged(auth, async (usuarioAutenticado) => {
        if (usuarioAutenticado) {
          const uid = usuarioAutenticado.uid;
          const userDocRef = doc(db, 'usuarios', uid);
          const userDocSnapshot = await getDoc(userDocRef);
  
          if (userDocSnapshot.exists()) {
            const data = userDocSnapshot.data();
            setNombreUsuario(data.nombreUsuario);
            setVerificado(data.verificado || false);
            setBetatester(data.betatester || false);
            setPersonal(data.personal || false);
            setArtista(data.artista || false);
            setComentario(data.comentario);
            setURL(data.url);
            setIMG(data.img);
          }
  
          setUsuario(usuarioAutenticado);
        } else {
          setUsuario(null);
          setNombreUsuario('');
          setVerificado(false);
        }
      });
  
      return () => unsubscribe();
    }, []);
  
    return (
        <Container>
            <div>
                <div className="informacion-perfil">
                <img className='imagen-perfil' src={img || 'https://firebasestorage.googleapis.com/v0/b/lisent-music.appspot.com/o/artistas%2Fartista_noDisponible.svg?alt=media&token=1bc3d7a5-a1f8-4082-bb60-47d29c90a416'} alt={nombreUsuario}/>
                <div className="info-perfil">
                    <h1 className="nombre-perfil">{nombreUsuario}
                    {verificado && <img src={VerificadoIMG} alt="Verificado" className="verificado-perfil" />}
                    </h1>
                    <p className='comentarios-perfil'>{comentario}</p>

                    <div className='contenedor-insignias'>
                    <div className="insignia">
                    {betatester && <img src={ExperimentadorIMG} alt="Experimentador" className="imagen-insignias" />}
                                        <div className="tooltip">Experimentador</div>
                                    </div>

                                    <div className="insignia">
                    {personal && <img src={PersonalIMG} alt="Personal" className="imagen-insignias" />}
                                        <div className="tooltip">Personal</div>
                                    </div>

                    </div>


                    
                </div>

                <div className="contenedor-botones">


<div className="botones-perfil">
    
{artista ? (
    <Link to={`/music/artista/${url}`}>
    <button className='artista-boton'>Perfil de artista</button>
    </Link>
    ) : (
        <Link to={"/music/ser-artista"}>
    <button className='artista-boton'>Conviertete en artista</button>
    </Link>
    )}
</div>

<div className="botones-perfil">
    <button onClick={()=>signOut(auth)} className="logout-boton">Cerrar sesión</button>
</div>
</div>
                </div>
                
                <div className="lisenttome-contenedor">
                    <h2 className='lisenttome-titulo'>Configuración de <strong>Lisent to me</strong></h2>
                    <p className="lisenttome-parrafo">Tu dispositivo aún no es compatible.</p>
                </div>
            </div>
        </Container>
        

    );
  }
  
  export default PerfilOptions;
