let musicDB = [
    {
        id: 0,
        name: "Dreamer",
        author_name: "Alan Walker",
        autor_url: "alanwalker",
        img: "https://firebasestorage.googleapis.com/v0/b/lisent-music.appspot.com/o/portadas%2Fmusica_dreamer.jpg?alt=media&token=c98a35bd-3d99-468e-b023-d069a9ed26f4",
        lang: null,
        timesPlayed: 0,
        type: null,
        musicName: "https://firebasestorage.googleapis.com/v0/b/lisent-music.appspot.com/o/musica%2FAlan%20Walker%20-%20Dreamer.mp3?alt=media&token=07480c7a-9be6-4cad-b015-b740df2262a3",
        attribution: {
            song: "Alan Walker - Dreamer [NCS Release]",
            musicBy: "NoCopyrightSounds",
            download: "http://ncs.io/dreamer",
            stream: "http://ncs.lnk.to/dreamerAT/youtube"
        }
    },
    {
        id: 1,
        name: "Sky High",
        author_name: "Elektronomia",
        autor_url: "elektronomia",
        img: "https://firebasestorage.googleapis.com/v0/b/lisent-music.appspot.com/o/portadas%2Fmusica_skyhigh.jpg?alt=media&token=817295ca-e99c-4be8-a00d-defe2b41a82e",
        lang: null,
        timesPlayed: 0,
        type:["fiestas", "streaming", "gaming"],
        musicName: "https://firebasestorage.googleapis.com/v0/b/lisent-music.appspot.com/o/musica%2FElektronomia%20-%20Sky%20High.mp3?alt=media&token=f41d3fdf-44c4-4d3b-ad86-02f47756b18e",
        attribution: {
            song: "Elektronomia - Sky High [NCS Release]",
            musicBy: "NoCopyrightSounds",
            download: "http://ncs.io/skyhigh",
            stream: "http://youtu.be/TW9d8vYrVFQ"
        }
    },
    {
        id: 2,
        name: "Pull Me In (Instrumental)",
        author_name: "Clarx & Shiah Maisel",
        autor_url: "clarx",
        img: "https://firebasestorage.googleapis.com/v0/b/lisent-music.appspot.com/o/portadas%2Fmusica_pullmein.jpg?alt=media&token=2c8cbe56-ddf2-4a90-ac0a-806a620b20e6",
        lang: null,
        type: ["combate", "streaming"],
        timesPlayed: 0,
        musicName: "https://firebasestorage.googleapis.com/v0/b/lisent-music.appspot.com/o/musica%2FClarx%20%26%20Shiah%20Maisel%20-%20Pull%20Me%20In.mp3?alt=media&token=200b7968-79ef-4d31-856e-9d5fd3d21bfe",
        attribution: {
            song: "Clarx & Shiah Maisel - Pull Me In (feat. M.I.M.E) [NCS Release]",
            musicBy: "NoCopyrightSounds",
            download: "http://ncs.io/PullMeIn",
            stream: "http://ncs.io/PullMeInAT/youtube"
        }
    },
    {
        id: 3,
        name: "Unbreakable (Instrumental)",
        author_name: "Lost Sky x ANGELPLAYA x Chris Linton",
        autor_url: "lostsky",
        img: "https://firebasestorage.googleapis.com/v0/b/lisent-music.appspot.com/o/portadas%2Fmusica_unbreakable.jpg?alt=media&token=a28447db-da58-4360-895c-ceab1137ab56",
        lang: null,
        timesPlayed: 0,
        type: ["combate","suspenso"],
        musicName: "https://firebasestorage.googleapis.com/v0/b/lisent-music.appspot.com/o/musica%2FLost%20Sky%20x%20ANGELPLAYA%20x%20Chris%20Linton%20-%20Unbreakable.mp3?alt=media&token=dc10715d-a4fd-4b3c-822c-a26535bb903b",
        attribution: {
            song: "Lost Sky x ANGELPLAYA x Chris Linton - Unbreakable [NCS Release]",
            musicBy: "NoCopyrightSounds",
            download: "http://ncs.io/Unbreakable",
            stream: "http://ncs.lnk.to/UnbreakableAT/youtube"
        }
    },
    {
        id: 4,
        name: "Color",
        author_name: "Grant (ft. Juneau)",
        autor_url: "grant",
        img: "https://firebasestorage.googleapis.com/v0/b/lisent-music.appspot.com/o/portadas%2Fmusica_color.jpg?alt=media&token=b31a25a3-b977-4184-9718-93898e600ccc",
        lang: null,
        timesPlayed: 0,
        type: ["gaming", "streaming"],
        musicName: "https://firebasestorage.googleapis.com/v0/b/lisent-music.appspot.com/o/musica%2FGrant%20-%20Color%20(feat.%20Juneau).mp3?alt=media&token=1ac001f7-8d6d-428d-b65c-71da8447d38a",
        attribution: {
            song: "Color",
            musicBy: "Grant (ft. Juneau)",
            download: null,
            stream: null
        }
    },
    {
        id: 5,
        name: "Keep You",
        author_name: "Rameses B",
        autor_url: "ramesesb",
        img: "https://firebasestorage.googleapis.com/v0/b/lisent-music.appspot.com/o/portadas%2Fmusica_keepyou.jpg?alt=media&token=e09a4870-0ba0-4a86-b784-a625a4db8b47",
        lang: null,
        timesPlayed: 0,
        type: ["combate", "gaming", "streaming"],
        musicName: "https://firebasestorage.googleapis.com/v0/b/lisent-music.appspot.com/o/musica%2FRameses%20B%20-%20Keep%20You.mp3?alt=media&token=48e35e46-3353-4382-ba27-7226b5823b68",
        attribution: {
            song: "Rameses B - Keep You [NCS Release]",
            musicBy: "NoCopyrightSounds",
            download: "https://ncs.io/RB_KeepYou",
            stream: "http://ncs.lnk.to/RB_KeepYouID"
        }
    },
    {
        id: 6,
        name: "Spectre",
        author_name: "Alan Walker",
        autor_url: "alanwalker",
        img: "https://firebasestorage.googleapis.com/v0/b/lisent-music.appspot.com/o/portadas%2Fmusica_spectre.jpg?alt=media&token=80c71d81-578c-4217-8965-37ecf57b086b",
        lang: null,
        timesPlayed: 0,
        type: ["gaming", "streaming"],
        musicName: "https://firebasestorage.googleapis.com/v0/b/lisent-music.appspot.com/o/musica%2FAlan%20Walker%20-%20Spectre.mp3?alt=media&token=068fbd31-5b0b-4f71-9f2c-bdb78f9fa36a",
        attribution: {
            song: "Alan Walker - Spectre [NCS Release]",
            musicBy: "NoCopyrightSounds",
            download: "https://soundcloud.com/alanwalker/alan-walker-spectre",
            stream: null
        }
    },
    {
        id: 7,
        name: "Energy",
        author_name: "Elektronomia",
        autor_url: "elektronomia",
        img: "https://firebasestorage.googleapis.com/v0/b/lisent-music.appspot.com/o/portadas%2Fmusica_energy.jpg?alt=media&token=e057aba7-da61-47b1-ac30-5c5b1becc573",
        lang: null,
        timesPlayed: 0,
        type: ["gaming", "streaming"],
        musicName: "https://firebasestorage.googleapis.com/v0/b/lisent-music.appspot.com/o/musica%2FElektronomia%20-%20Energy.mp3?alt=media&token=d4c37f35-4b22-4081-9090-8285f0ceb84d",
        attribution: {
            song: "Elektronomia - Energy [NCS Release]",
            musicBy: "NoCopyrightSounds",
            download: "http://ncs.io/energy",
            stream: "http://youtu.be/fzNMd3Tu1Zw"
        }
    },
    {
        id: 8,
        name: "Limitless",
        author_name: "Elektronomia",
        autor_url: "elektronomia",
        img: "https://firebasestorage.googleapis.com/v0/b/lisent-music.appspot.com/o/portadas%2Fmusica_limitless.jpg?alt=media&token=6ad2b39e-772b-4d24-bc85-7bde5eae2fc6",
        lang: null,
        timesPlayed: 0,
        type: ["gaming", "streaming"],
        musicName: "https://firebasestorage.googleapis.com/v0/b/lisent-music.appspot.com/o/musica%2FElektronomia%20-%20Limitless.mp3?alt=media&token=76ef4f3c-e7d7-49d4-9935-32776f30e108",
        attribution: {
            song: "Elektronomia - Limitless [NCS Release]",
            musicBy: "NoCopyrightSounds",
            download: "http://ncs.io/Limitless",
            stream: "http://youtu.be/cNcy3J4x62M"
        }
    },
    {
        id: 9,
        name: "The Other Side",
        author_name: "Elektronomia",
        autor_url: "elektronomia",
        img: "https://firebasestorage.googleapis.com/v0/b/lisent-music.appspot.com/o/portadas%2Fmusica_theotherside.jpg?alt=media&token=77e944a6-ccfc-4513-a8b8-3b18e510c24b",
        lang: null,
        timesPlayed: 0,
        type: ["gaming", "streaming"],
        musicName: "https://firebasestorage.googleapis.com/v0/b/lisent-music.appspot.com/o/musica%2FElektronomia%20-%20The%20Other%20Side.mp3?alt=media&token=6c02ca2c-acad-49e4-856a-d9c78ca847ed",
        attribution: {
            song: "Elektronomia - The Other Side [NCS Release]",
            musicBy: "NoCopyrightSounds",
            download: "http://ncs.io/TheOtherSide",
            stream: "http://youtu.be/odThebFOFVg"
        }
    },
    {
        id: 10,
        name: "Twilight",
        author_name: "Kupla",
        autor_url: "kupla",
        img: "https://firebasestorage.googleapis.com/v0/b/lisent-music.appspot.com/o/portadas%2Fmusica_twilight.jpg?alt=media&token=88d93eac-a0fa-4f61-8009-6ff0da89863c",
        lang: null,
        timesPlayed: 0,
        type: ["estudios","chill"],
        musicName: "https://firebasestorage.googleapis.com/v0/b/lisent-music.appspot.com/o/musica%2FKupla%20-%20Twilight.mp3?alt=media&token=a16802b2-bb41-48fa-91f9-e379368172d7",
        attribution: {
            song: "Kupla - Twilight",
            musicBy: "Lofi Records",
            download: "https://fanlink.to/lifeforms",
            stream: "https://youtu.be/_zuVOdOiGlo"
        }
    },
    {
        id: 11,
        name: "Trust Me",
        author_name: "Sátyr x Phlocalyst",
        autor_url: ["satyr", "phlocalyst"],
        img: "https://firebasestorage.googleapis.com/v0/b/lisent-music.appspot.com/o/portadas%2Fmusica_trustme.jpg?alt=media&token=309e694e-c22d-4292-83f1-1f9b1fd789f2",
        lang: null,
        timesPlayed: 0,
        type: ["estudios","chill"],
        musicName: "https://firebasestorage.googleapis.com/v0/b/lisent-music.appspot.com/o/musica%2FS%C3%A1tyr%20x%20Phlocalyst%20-%20Trust%20Me.mp3?alt=media&token=f1f7fe13-d5b7-4f64-8097-606786874fc2",
        attribution: {
            song: "Sátyr x Phlocalyst - Trust Me",
            musicBy: "Lofi Records",
            download: "https://fanlink.to/Aiode",
            stream: "https://youtu.be/6kN0efHcNw8"
        }
    },
    {
        id: 12,
        name: "Dots",
        author_name: "Blue Wednesday",
        autor_url: "bluewednesday",
        img: "https://firebasestorage.googleapis.com/v0/b/lisent-music.appspot.com/o/portadas%2Fmusica_dots.jpg?alt=media&token=f0ab608c-4f2f-4563-bddc-22e02b7f7827",
        lang: null,
        timesPlayed: 0,
        type: ["estudios","chill"],
        musicName: "https://firebasestorage.googleapis.com/v0/b/lisent-music.appspot.com/o/musica%2FBlue%20Wednesday%20-%20Dots.mp3?alt=media&token=33199cb3-d47c-4914-b7dd-e1bd615d5134",
        attribution: {
            song: "Blue Wednesday - Dots",
            musicBy: "Lofi Records",
            download: "https://fanlink.to/AfterHoursEp",
            stream: "https://youtu.be/3bsOzH6jLdI"
        }
    },
    {
        id: 13,
        name: "Moonscapes",
        author_name: "S N U G x Nuver",
        autor_url: ["snug","nuver"],
        img: "https://firebasestorage.googleapis.com/v0/b/lisent-music.appspot.com/o/portadas%2Fmusica_moonscapes.jpg?alt=media&token=e3235004-7066-4424-b659-76dde2c594c7",
        lang: null,
        timesPlayed: 0,
        type: ["estudios","chill"],
        musicName: "https://firebasestorage.googleapis.com/v0/b/lisent-music.appspot.com/o/musica%2FS%20N%20U%20G%20x%20Nuver%20-%20Moonscapes.mp3?alt=media&token=313dace2-4bac-49e9-9ba0-76ba361ae10f",
        attribution: {
            song: "S N U G x Nuver - Moonscapes",
            musicBy: "Lofi Records",
            download: "https://fanlink.to/NightfallEp",
            stream: "https://youtu.be/P7q6oPMIdLk"
        }
    },
    {
        id: 14,
        name: "Words on Water",
        author_name: "Hoogway x Softy",
        autor_url: ["softy","hoogway"],
        img: "https://firebasestorage.googleapis.com/v0/b/lisent-music.appspot.com/o/portadas%2Fmusica_wordsonwater.jpg?alt=media&token=b1528581-87fa-409f-adc4-ad4adb8eb85f",
        lang: null,
        timesPlayed: 0,
        type: ["estudios","chill"],
        musicName: "https://firebasestorage.googleapis.com/v0/b/lisent-music.appspot.com/o/musica%2FHoogway%20x%20Softy%20-%20Words%20on%20Water.mp3?alt=media&token=ff0065d9-9742-424a-9167-cbbbb6edf65c",
        attribution: {
            song: "Hoogway x Softy - Words on Water",
            musicBy: "Lofi Records",
            download: "https://fanlink.to/AlleyOfTrees",
            stream: "https://youtu.be/0PiXj2WHYrw"
        }
    },
    {
        id: 15,
        name: "Lakeview",
        author_name: "Softy x No one’s perfect",
        autor_url: ["softy","noonesperfmusic"],
        img: "https://firebasestorage.googleapis.com/v0/b/lisent-music.appspot.com/o/portadas%2Fmusica_lakeview.jpg?alt=media&token=fb3767f9-ec23-454b-ac52-7e27e777aafe",
        lang: null,
        timesPlayed: 0,
        type: ["estudios","chill"],
        musicName: "https://firebasestorage.googleapis.com/v0/b/lisent-music.appspot.com/o/musica%2Fsofty%20x%20no%20one%E2%80%99s%20perfect%20-%20Lakeview.mp3?alt=media&token=90fc0109-37c8-4d3b-a181-b37a36e81b40",
        attribution: {
            song: "softy x no one’s perfect - Lakeview",
            musicBy: "Lofi Records",
            download: "https://fanlink.to/TheBeautyAroundUs",
            stream: "https://youtu.be/rVgwN8Ts3ec"
        }
    },
    {
        id: 16,
        name: "Home pour",
        author_name: "less.people",
        autor_url: "less.people",
        img: "https://firebasestorage.googleapis.com/v0/b/lisent-music.appspot.com/o/portadas%2Fmusica_homepour.jpg?alt=media&token=2d8fa374-c39f-4a35-86a5-80852d795e9b",
        lang: null,
        timesPlayed: 0,
        type: ["estudios","chill"],
        musicName: "https://firebasestorage.googleapis.com/v0/b/lisent-music.appspot.com/o/musica%2Flesspeople%20-%20Home%20pour.mp3?alt=media&token=34c4fe36-89ff-4641-8417-117443201791",
        attribution: {
            song: "less.people - Home pour",
            musicBy: "Lofi Records",
            download: "https://fanlink.to/Online-Mall-Music",
            stream: "https://youtu.be/h4ClojdLTMg"
        }
    },
    {
        id: 17,
        name: "Soul Searching",
        author_name: "l'Outlander",
        autor_url: "l_outlander",
        img: "https://firebasestorage.googleapis.com/v0/b/lisent-music.appspot.com/o/portadas%2Fmusica_soulsearching.jpg?alt=media&token=3f188069-1ff9-41f5-87aa-e0ad4c39b4dc",
        lang: null,
        timesPlayed: 0,
        type: ["estudios","chill"],
        musicName: "https://firebasestorage.googleapis.com/v0/b/lisent-music.appspot.com/o/musica%2Fl'Outlander%20-%20Soul%20Searching.mp3?alt=media&token=3961ad7d-5638-4a0c-975a-660c812450b8",
        attribution: {
            song: "l'Outlander - Soul Searching",
            musicBy: "Lofi Records",
            download: "https://fanlink.to/OneWayTicket",
            stream: "https://youtu.be/f6GP7iBVxH8"
        }
    },
    {
        id: 18,
        name: "Abundance",
        author_name: "Chris Mazuera x Tender",
        autor_url: ["chrismazuera","tender_spring"],
        img: "https://firebasestorage.googleapis.com/v0/b/lisent-music.appspot.com/o/portadas%2Fmusica_abundance.jpg?alt=media&token=5a234773-55b8-40bb-850f-4ef68a514e78",
        lang: null,
        timesPlayed: 0,
        type: ["estudios","chill"],
        musicName: "https://firebasestorage.googleapis.com/v0/b/lisent-music.appspot.com/o/musica%2FChris%20Mazuera%20x%20Tender%20Spring%20-%20Abundance.mp3?alt=media&token=94f790bd-fa6b-4765-85f0-2189542a668f",
        attribution: {
            song: "Chris Mazuera x Tender Spring - Abundance",
            musicBy: "Lofi Records",
            download: "https://fanlink.to/Perspective-EP",
            stream: "https://youtu.be/gdo5l50_ym4"
        }
    },
    {
        id: 19,
        name: "Their Chair",
        author_name: "Kudo",
        autor_url: "kudo",
        img: "https://firebasestorage.googleapis.com/v0/b/lisent-music.appspot.com/o/portadas%2Fmusica_theirchair.jpg?alt=media&token=47801514-7175-4188-9811-faa08f47e7ca",
        lang: null,
        timesPlayed: 0,
        type: ["estudios","chill"],
        musicName: "https://firebasestorage.googleapis.com/v0/b/lisent-music.appspot.com/o/musica%2FKudo%20-%20Their%20Chair.mp3?alt=media&token=eda5235f-a07a-4afb-804a-1327e56d145e",
        attribution: {
            song: "Kudo - Their Chair",
            musicBy: "Lofi Records",
            download: "https://fanlink.to/LoveYouTwo",
            stream: "https://youtu.be/M8dbvBYNtfg"
        }
    },
    {
        id: 20,
        name: "Dark Chocolate",
        author_name: "Purrple Cat",
        autor_url: "purrplecat",
        img: "https://firebasestorage.googleapis.com/v0/b/lisent-music.appspot.com/o/portadas%2Fmusica_darkchocolate.jpg?alt=media&token=e677cae5-6898-4450-b27e-357e5c4f54a8",
        lang: null,
        timesPlayed: 0,
        type: ["estudios","chill","suspenso"],
        musicName: "https://firebasestorage.googleapis.com/v0/b/lisent-music.appspot.com/o/musica%2FPurrple%20Cat%20-%20Dark%20Chocolate.mp3?alt=media&token=60cd4e27-0eef-46c4-881b-98e29285ad5b",
        attribution: {
            song: "Purrple Cat - Dark Chocolate",
            musicBy: "Lofi Records",
            download: "https://fanlink.to/SweetDreamsEp",
            stream: "https://youtu.be/_5fFSbsd8YQ"
        }
    },
    {
        id: 21,
        name: "Remembering You",
        author_name: "fourwalls x Towerz",
        autor_url: ["fourwalls","towerz"],
        img: "https://firebasestorage.googleapis.com/v0/b/lisent-music.appspot.com/o/portadas%2Fmusica_rememberingyou.jpg?alt=media&token=ad8027c7-c29c-4dda-afda-b802ddc08476",
        lang: null,
        timesPlayed: 0,
        type: ["relax","chill","dormir"],
        musicName: "https://firebasestorage.googleapis.com/v0/b/lisent-music.appspot.com/o/musica%2Ffourwalls%20x%20Towerz%20%E2%80%93%20Remembering%20You.mp3?alt=media&token=b36d0ba0-d776-44ef-ac87-d5a8e2f4bc08",
        attribution: {
            song: "fourwalls x Towerz - Remembering You",
            musicBy: "Lofi Records",
            download: "https://fanlink.to/StaringContest",
            stream: "https://youtu.be/zKomku2GAr8"
        }
    },
    {
        id: 22,
        name: "Moon",
        author_name: "Allem Iversom",
        autor_url: "allemiversom",
        img: "https://firebasestorage.googleapis.com/v0/b/lisent-music.appspot.com/o/portadas%2Fmusica_moon.jpg?alt=media&token=e07b88d2-00da-44d5-9433-65c72b17f461",
        lang: null,
        timesPlayed: 0,
        type: ["relax","chill","dormir"],
        musicName: "https://firebasestorage.googleapis.com/v0/b/lisent-music.appspot.com/o/musica%2FAllem%20Iversom%20-%20Moon.mp3?alt=media&token=79bfe39c-4903-4bd4-8c8b-b2bc96910fc1",
        attribution: {
            song: "Allem Iversom - Moon",
            musicBy: "Lofi Records",
            download: "https://fanlink.to/TheRidge",
            stream: "https://youtu.be/j0-ZYZi_9qA"
        }
    },
    {
        id: 23,
        name: "Midnight Thoughts",
        author_name: "Yasumu",
        autor_url: "yasumubeats",
        img: "https://firebasestorage.googleapis.com/v0/b/lisent-music.appspot.com/o/portadas%2Fmusica_midnightthoughts.jpg?alt=media&token=33a68744-a27a-4de0-9142-24bfb6cebb85",
        lang: null,
        timesPlayed: 0,
        type: ["relax","chill","dormir"],
        musicName: "https://firebasestorage.googleapis.com/v0/b/lisent-music.appspot.com/o/musica%2FYasumu%20-%20Midnight%20Thoughts.mp3?alt=media&token=b9236e69-237d-4bc2-bd39-b9a87a8b2d6c",
        attribution: {
            song: "Yasumu - Midnight Thoughts",
            musicBy: "Lofi Records",
            download: "https://fanlink.to/WayOfLife",
            stream: "https://youtu.be/APwG641sAEQ"
        }
    },
    {
        id: 24,
        name: "A lonely star",
        author_name: "Jhove",
        autor_url: "jhovebeats",
        img: "https://firebasestorage.googleapis.com/v0/b/lisent-music.appspot.com/o/portadas%2Fmusica_alonelystar.jpg?alt=media&token=2ef99300-156f-4e3e-9c69-209bbcc08e77",
        lang: null,
        timesPlayed: 0,
        type: ["relax","chill","dormir"],
        musicName: "https://firebasestorage.googleapis.com/v0/b/lisent-music.appspot.com/o/musica%2Fjhove%20-%20a%20lonely%20star.mp3?alt=media&token=4f0ee2e5-cdea-423f-843b-3c8a37af8839",
        attribution: {
            song: "jhove - a lonely star",
            musicBy: "Lofi Records",
            download: "https://fanlink.to/FallingDreams",
            stream: "https://youtu.be/hbfgVxuULpk"
        }
    },
];

export default musicDB;