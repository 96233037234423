import React from 'react';
import '../assets/scss/FooterSelectMusic.scss';
import {Link} from "react-router-dom";

function FooterSelectMusic() {
    

    return (
        <div style={{display: 'none'}}>
            <Link to={"/"}>
                ¿Qué escucharemos hoy?
            </Link>
        </div>
    );
}

export default FooterSelectMusic;