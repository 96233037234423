import React from 'react';
import './css/Actividades.scss';
import Container from "../fragment/Container";
import FiltrosActividades from "../fragment/FiltrosActividades";
import ActividadImagen from "../assets/img/VectorActividad1.svg"

const Actividades = () => {
    return (
        <Container>
            <div>
            <div className="actividades-contenedor">
             <h1 className="actividades-titulo">Cada actividad,<br/>tiene su estilo.</h1>
        

        <FiltrosActividades/>

        </div>
    
            <div className="actividades-banner">
                <h2 className='banner-titulo'>Tú eliges la actividad, nosotros ponemos la música.</h2>
                <p className='banner-parrafo'>Cocinar, estudiar o tal vez relajarse. Si necesitas ritmo, nosotros te lo damos con nuestras listas de reproducción.</p>

                <img src={ActividadImagen} alt="Actividades" className="banner-imagen" />
            </div>

            </div>
            
            
        </Container>
    );
}

export default Actividades;
