import React, {useState, useEffect} from "react";
import "../assets/scss/SideBar.scss";
import SideBarOptions from "./SideBarOptions";
import {ExploreOutlined, PeopleAltOutlined, DirectionsRunOutlined, HistoryOutlined, FavoriteBorderOutlined, AddBoxOutlined} from "@material-ui/icons";
import { useLocation } from 'react-router-dom';

function SideBar() {
    const location = useLocation();
    const [activeButton, setActiveButton] = useState("Explorar");
  
    useEffect(() => {
      if (location.pathname === "/music") {
        setActiveButton("Explorar");
      } else if (location.pathname === "/music/artistas") {
        setActiveButton("Artistas");
      } else if (location.pathname === "/music/actividades") {
        setActiveButton("Actividades");
        } else if (location.pathname === "/music/history") {
        setActiveButton("Historial");
    } else if (location.pathname === "/music/favoritos") {
        setActiveButton("Favoritos");
      }
      
    }, [location.pathname]);

    return (
        <aside className={"aside-bar"}>
            <div className="aside-bar-container">
                <p className={"p1"}>
                    <span>MENÚ</span>
                </p>
                <SideBarOptions className={"lib-sub"} Icon={ExploreOutlined} href={"/music"} title={"Explorar"} isActive={activeButton === "Explorar"}/>
                <SideBarOptions className={"lib-sub"} Icon={PeopleAltOutlined} href={"/music/artistas"}  title={"Artistas"} isActive={activeButton === "Artistas"}/>
                <SideBarOptions className={"lib-sub"} Icon={DirectionsRunOutlined} href={"/music/actividades"}  title={"Actividades"} isActive={activeButton === "Actividades"}/>
            </div>

            <div className="aside-bar-container">
                <p className={"p1"}>
                    <span>BIBLIOTECA</span>
                </p>
                <SideBarOptions className={"lib-sub"} Icon={HistoryOutlined} href={"#"} title={"Historial"} isActive={activeButton === "Historial"}/>
                <SideBarOptions className={"lib-sub"} Icon={FavoriteBorderOutlined} href={"#"} title={"Favoritos"} isActive={activeButton === "Favoritos"}/>
            </div>

            <div className="aside-bar-container playlist">
                <p className={"p1"}>
                    <span>PLAYLIST</span>
                </p>
                <SideBarOptions className={"lib-sub"} Icon={AddBoxOutlined} href={"#"}  title={"Crear nueva"}/>
            </div>
        </aside>
    );
}

/*
*
* */
export default SideBar;