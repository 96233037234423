import React from 'react';
import '../assets/scss/ArtistasPlaylist.scss';
import Container from "./Container";
import { useSelector } from "react-redux";
import MusicCard from "./MusicCard";
import { useLocation } from 'react-router-dom';
import artistasDB from '../../db/artistas.json';
import Verificado from '../assets/img/Lisent_Verificado.svg'
import AutoVerificado from '../assets/img/Lisent_AutoVerificado.svg';
import LibraryMusic from "@material-ui/icons/LibraryMusic";
const ArtistasPlaylist = () => {
    const location = useLocation();
    const typeOfPlaylist = location.pathname.split('/').pop();
    const { playlists } = useSelector(state => state.musicReducer);

    const artistasArray = Object.values(artistasDB);

    const artistaInfo = artistasArray.find(artista => artista.url === typeOfPlaylist);

    return (
        <Container>
            <div>
                <div className="informacion-artistaplaylist">
                    {artistaInfo ? (
                        <>
                            <img className='imagen-artistaplaylist' src={artistaInfo.img} alt={artistaInfo.nombre} />
                            <div className='info-text'>
                                <h1 className='nombre-artistaplaylist'>
                                    {artistaInfo.nombre}
                                    {artistaInfo.verificado && (
                                        <img className='verificado-artistaplaylist' src={Verificado} alt={"Verificado"} />
                                        
                                    )}
                                    {artistaInfo.autoverificado && (
                                        <img className='verificado-artistaplaylist' src={AutoVerificado} alt={"Verificado"} />
                                        
                                    )}
                                </h1>
                                <p className='comentarios-artistaplaylist'>{artistaInfo.comentarios}</p>
    
                        {artistaInfo.insignias && (
                        <div className='contenedor-insignias'>
                            {Object.entries(artistaInfo.insignias).map(([insignia, valor]) => (
                                valor && (
                                    <div key={insignia} className={"insignia"}>
                                        <img
                                            className='imagen-insignias'
                                            src={require(`../assets/insignias/${insignia}.svg`)}
                                            alt={insignia}
                                        />
                                        <div className="tooltip">{insignia}</div>
                                    </div>
                                )
                            ))}
                        </div>
                    )}


                            </div>
                        </>
                    ) : (
                        <p>Conectando...</p>
                    )}
                </div>
    
                <div className={"Playlist"}>
                    <h2 className='creaciones-artistaplaylist'><LibraryMusic/> Creaciones de {artistaInfo ? artistaInfo.nombre : ''}</h2>
                    <div className="Playlist-container">
                        {playlists.map((item) => (
                            item.autor_url && item.autor_url.includes(typeOfPlaylist) &&
                            <MusicCard key={item.id} music={item} />
                        ))}
                    </div>
                </div>
            </div>
        </Container>
    );
}

export default ArtistasPlaylist;
