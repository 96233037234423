import React from 'react';
import './css/Artistas.scss';
import Container from "../fragment/Container";
import ArtistasDatos from "../fragment/ArtistasDatos";

const Artistas = () => {

    return (
        <Container>
            <div>
            <div className="artistas-contenedor">
             <h1 className="artistas-titulo">Encuentra al artista,<br/>que te comparte energía.</h1>
             <p className="artistas-parrafo"><strong>CONSEJO:</strong> También puedes buscar los artistas en el buscador.</p>
        </div>

        <ArtistasDatos/>
            </div>
            
            
        </Container>
    );
}

export default Artistas;
