import React from 'react';
import { NavLink } from 'react-router-dom';
import '../assets/scss/NavigationButton.scss';

const estilosNavLink = {
  color: '#ffffff',
};

const estilosNavLinkActivo = {
  color: '#5773FF',
};

function NavigationButton(props) {
  return (
    <NavLink
      to={props.href}
      className="Nav-btn"
      style={props.isActive ? estilosNavLinkActivo : estilosNavLink}
    >
      {props.name}
    </NavLink>
  );
}

export default NavigationButton;
