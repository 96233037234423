import React, { useState } from 'react';
import './css/ContrasenaCambio.scss'
import appFirebase from '../../lisentc';
import Isotipo from "../assets/img/Isotipo_Lisent_Blanco.svg"
import { getAuth, sendPasswordResetEmail } from 'firebase/auth';
import { Link } from 'react-router-dom';

const CambioPass = () => {
  const auth = getAuth(appFirebase);
  const [mensaje, setMensaje] = useState(null);

  const funcReset = async (e) => {
    e.preventDefault();

    const correo = e.target.email.value;

    try {
      await sendPasswordResetEmail(auth, correo);
      setMensaje('Correo de reestablecimiento de contraseña enviado con éxito.');
    } catch (error) {
      setMensaje('No se ha logrado reestablecer la contraseña, porfavor comunícate con soporte en Discord o X (Twitter)');
    }
  };

  return (
    <div className='formulario-olvidadizo'>
      <img src={Isotipo} alt="Lisent_Isotipo" className='logolisent' />
      <form onSubmit={funcReset}>
        <input className='correo-olvidadizo' type="email" placeholder='tucorreo@lisentmusic.com' id="email" />
        <button className="boton-olvidadizo" type="submit">Reestablecer Contraseña</button>
      </form>

      {mensaje && (
        <div>
          <p className='mensaje-olvidadizo'>{mensaje}</p>
        </div>
      )}

      <Link to="/music/perfil"><button className='volver-olvidadizo'>Volver</button></Link>
    </div>
  );
};

export default CambioPass;
