import React, {useContext} from "react";
import '../assets/scss/DropDown.scss';

import {ThemeContext} from "../../api/Theme";

const DropDownLanguageList = () => {

    const useStyle = useContext(ThemeContext);

    
    


    return (
        <div style={useStyle.component} className="dropdown">
            <div className="dropdown-head">
                <p>No hay notificaciones.</p>
            </div>
        </div>
    );
}

export default DropDownLanguageList;