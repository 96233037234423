import React from 'react';
import '../assets/scss/Name.scss';
import '../assets/scss/NombreArtistas.scss';
import Verificado from '../assets/img/Lisent_Verificado.svg';
import AutoVerificado from '../assets/img/Lisent_AutoVerificado.svg';

function Name({ className, length, name, isVerified, isAutoVerified }) {
    const shortenedName = name.length > length ? `${name.substring(0, length)}...` : name;

    return (
        <div className={`${className} name-container`}>
            <p>
                {shortenedName}
            </p>
            {isVerified && (
                <img className='verificado-datosartistas' src={Verificado} alt={"Verificado"} />
            )}
            
            {isAutoVerified && (
                <img className='verificado-datosartistas' src={AutoVerificado} alt={"AutoVerificado"} />
            )}
        </div>
    );
}

export default Name;
