import React, {useRef} from "react";
import {Button} from "@material-ui/core";
import {Link} from "react-router-dom";

const estilosSideBar = {
    color: '#ffffff',
  };
  
  const estilosSideBarActivo = {
    color: '#5773FF',
  };

  
function SideBarOptions(props) {
    const Icon = props.Icon;
    const title = props.title;
    const className = props.className;
    const sideBarRef = useRef();
    const href = props.href;
    return (
        <Button style={props.isActive ? estilosSideBarActivo : estilosSideBar} onClick={()=>{sideBarRef.current.click();}} className={className} startIcon={Icon && <Icon style={props.isActive ? estilosSideBarActivo : estilosSideBar}/>}>
            <Link ref={sideBarRef} to={href}/>
            {title}
        </Button>
    );
}

export default SideBarOptions;