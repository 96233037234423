import React, {useState} from "react";
import '../assets/scss/BottomNavigation.scss';
import HomeIcon from '@material-ui/icons/Home';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import DirectionsRunIcon from '@material-ui/icons/DirectionsRun';
import PeopleAlt from '@material-ui/icons/PeopleAlt';
import Button from "@material-ui/core/Button";
import {Link} from "react-router-dom";

function BottomNavigationMobile() {
    const [menuItems] = useState([
        {
            id: 0,
            icon: <HomeIcon/>,
            href: "/music",
            label: "Inicio"
        },
        {
            id: 1,
            icon: <DirectionsRunIcon/>,
            href: "/music/actividades",
            label: "Actividades"
        },
        {
            id: 2,
            icon: <PeopleAlt/>,
            href: "/music/artistas",
            label: "Artistas"
        },
        {
            id: 3,
            icon: <AccountCircleIcon/>,
            href: "/music/perfil",
            label: "Cuenta"
        }
    ]);
    let currPath = window.location.pathname;
    return (
        <div className="bottom-navigation">
            {
                menuItems.map(({id, icon, href, label}) => (
                    <Link className={"bottom-navigation-link"} key={id} to={href}>
                        <Button className={`${currPath === href ? "BottomNavAction active" : "BottomNavAction"}`}
                                    style={{borderRadius: 0}}>
                            {icon}
                        </Button>
                    </Link>
                ))
            }
        </div>
    );
}

export default BottomNavigationMobile;