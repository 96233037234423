import React from 'react'
import {Link} from "react-router-dom";

const FiltrosActividades = () => {
    return ( 
        <div className="filtros">
        <Link to="/music/playlist/streaming">
        <button className="filtrobtn">Streaming</button>
        </Link>

        <Link to="/music/playlist/gaming">
        <button className="filtrobtn">Gaming</button>
        </Link>

        <Link to="/music/playlist/relax">
        <button className="filtrobtn">Relax</button>
        </Link>

        <Link to="/music/playlist/aventura">
        <button className="filtrobtn">Aventura</button>
        </Link>
        <Link to="/music/playlist/combate">
        <button className="filtrobtn">Combate</button>
        </Link>
        <Link to="/music/playlist/estudios">
        <button className="filtrobtn">Estudios</button>
        </Link>
        <Link to="/music/playlist/cocina">
        <button className="filtrobtn">Cocina</button>
        </Link>
        <Link to="/music/playlist/chill">
        <button className="filtrobtn">Chill</button>
        </Link>

        <p className="ver-mas">
            <Link to="#">Ver más</Link>
        </p>  
    </div>

     );
}
 
export default FiltrosActividades;