import React, { useEffect, Fragment } from 'react'
import Logo from "../assets/img/LisentLogo.svg"
import './css/Contribuir.scss'
import {Link} from "react-router-dom";

const Contribuir = () => {

    useEffect(() => {
        document.title = "Contribuir | Lisent"
    });
    return ( 
        <Fragment>
            <div className="contenedor-colaborar">
                
                <h1 className="titulo-colaborar"><strong>Colabora</strong> en un<br/>próximo <strong>objetivo</strong>.</h1>
                <Link to="/">
                <img src={Logo} alt="Lisent Logo" className='imagen-colaborar'/>
                </Link>
            </div>

            <div className="objetivos-colaborar">
                <h2 className='titulo-objetivos'>Lanzamiento de aplicación en Android</h2>
                <p className='parrafo-objetivos'>Queremos incluirnos en dispositivos móviles, ofreciendo increíbles opciones. </p>

                
        <button onClick={() => window.open("https://www.paypal.me/damiiawa", "_blank")} className="boton-objetivos">Aportar vía PayPal</button>
        
            </div>

            <div className="objetivos-colaborar">
                <h2 className='titulo-objetivos'>Contribuye música</h2>
                <p className='parrafo-objetivos'>Pudes aportar música libre a través de nuestro servidor de Discord.</p>

        <button onClick={() => window.open("https://discord.gg/nCXQrBr9yX", "_blank")} className="boton-objetivos">Unirse al servidor</button>
            </div>
        </Fragment>
     );
}
 
export default Contribuir;