import React, { Fragment, useState } from 'react';
import '../assets/scss/Acceso.scss';
import Isotipo from "../assets/img/Isotipo_Lisent_Blanco.svg"
import appFirebase from '../../lisentc';
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword} from 'firebase/auth';
import { getFirestore, addDoc, collection, doc, setDoc, getDocs} from "firebase/firestore";
import { Link } from 'react-router-dom';

const auth = getAuth(appFirebase);
const db = getFirestore();

function Acceso() {
  const [registrando, setRegistrando] = useState(false);
  const [error, setError] = useState(null);
  const [nombreUsuario, setNombreUsuario] = useState('');

  const funcAutenticacion = async (e) => {
    e.preventDefault();

    const correo = e.target.email.value;
    const contrasena = e.target.password.value;

    if (!correo || !contrasena) {
      setError('Debes rellenar toda la información solicitada.');
      return;
    }

    if (contrasena.length < 8) {
      setError('La contraseña debe tener al menos 8 caracteres.');
      return;
    }

    if (registrando && !nombreUsuario) {
      setError('Debes ingresar un nombre de usuario.');
      return;
    }

    if (registrando && nombreUsuario.length < 3) {
      setError('El nombre de usuario debe tener al menos 3 caracteres.');
      return;
    }

    setError(null);

    if (registrando) {
      try {
        
        const usernameExistsQuery = collection(db, 'usuarios');
        const usernameSnapshot = await getDocs(usernameExistsQuery);

        if (usernameSnapshot.docs.some(doc => doc.data().nombreUsuario === nombreUsuario)) {
          setError('Ya existe alguien con ese nombre. Elige otro.');
          return;
        }

       
        const userCredential = await createUserWithEmailAndPassword(auth, correo, contrasena, { displayName: nombreUsuario });
        const uid = userCredential.user.uid;

        const userDocRef = doc(db, "usuarios", uid);
        await setDoc(userDocRef, {
          nombreUsuario: nombreUsuario,
          verificado: false,
          artista: false,
          betatester: false,
          personal: false,
          comentario: 'Sin URL personalizada',
        });

      } catch (error) {
        setError('Es posible que el correo ya este en uso.');
      }
    } else {
      try {
        await signInWithEmailAndPassword(auth, correo, contrasena);
      } catch (error) {
        setError('El correo o la contraseña son incorrectos.');
      }
    }
  };

  return (
    <Fragment>
      <div className="formulario-contenedor">
        <img src={Isotipo} alt="Lisent_Isotipo" className='logolisent' />
        <form className="formulario" onSubmit={funcAutenticacion}>
          
          
        {registrando && (
            <Fragment>
              <p className="usuarioform-parrafo">Crea un nombre de usuario</p>
              <input className="usuarioform" type="text" placeholder="@LisentMusic" value={nombreUsuario} onChange={(e) => setNombreUsuario(e.target.value)} />
            </Fragment>
          )}
          <p className="correoform-parrafo">Ingresa tu correo</p>
          <input className="correoform" type="email" placeholder="tucorreo@lisentmusic.com" id="email" />
          
          <p className="contraform-parrafo">Ingresa tu contraseña</p>
          <input className="contraform" type="password" placeholder="12345678" id="password" />


          {error && <p className="mensaje-error">{error}</p>}

          <button className="botonform">{registrando ? 'Unirme a Lisent' : 'Acceder a Lisent'}</button>
        </form>

        <p className='preguntaform'>
          {registrando ? '¿Ya tienes una cuenta?' : '¿Aún no tienes una cuenta?'}
          <button className="botonpreguntaform" onClick={() => setRegistrando(!registrando)}>
            {registrando ? 'Accede' : 'Regístrate'}
          </button>
        </p>

        <Link to="/olvidadizo"><button className='volver-olvidadizo'>¿Olvidaste tu contraseña?</button></Link>
      </div>
    </Fragment>
  );
}

export default Acceso;
