import React, { Fragment } from 'react'
import MusicCardContainer from "../fragment/MusicCardContainer";
import '../Pages/css/Home.scss';
import Logo from "../assets/img/LisentLogo.svg"
import Isotipo from "../assets/img/Isotipo_Lisent_Blanco.svg"
import {Link} from "react-router-dom";


const Inicio = () => {
    return ( 
        <Fragment>
                <div className="contenedorNovedades">
        <h1 className="tituloNovedades"><img src={Logo} height={"50px"} alt="Lisent Logo"/></h1>
        <p className="parrafoNovedades">Escucha música libre sin interrupciones.</p>
        <Link to="/music/artistas">
        <button to="/music/artistas" className="botonNovedades">Descubre artistas <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
  <path d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"/>
</svg></button>
</Link>

                    <img src={Isotipo} alt="Lisent Isotipo" className='imagenNovedades'/>

    </div>

        <MusicCardContainer/>
        </Fragment>
     );
}
 
export default Inicio;