import React, { Fragment, useState } from 'react'
import Acceso from '../fragment/Acceso'
import PerfilOptions from '../fragment/PerfilOptions'

import appFirebase from '../../lisentc'
import {getAuth, onAuthStateChanged} from 'firebase/auth'

const auth = getAuth(appFirebase)


function Perfil(){

    const [usuario, setUsuario] = useState(null)

    onAuthStateChanged(auth, (usuarioFirebase)=>{
        if (usuarioFirebase) {
            setUsuario(usuarioFirebase)
        }else{
            setUsuario(null)
        }

    })


    return ( 
        <Fragment>
            {usuario ? <PerfilOptions correoUsuario = {usuario.email}/> :  <Acceso/>}
        </Fragment>
     );
}
 
export default Perfil;