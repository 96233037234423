import React, {useState, useEffect} from "react";
import '../assets/scss/Navigation.scss';
import DropDownLanguageList from "./DropDownLanguageList";
import SearchBar from "./SearchBar";
import Brand from "./Brand";
import {Button} from "@material-ui/core";
import {Link} from "react-router-dom";
import NavigationButton from './NavigationButton'
import { useLocation } from 'react-router-dom';

import appFirebase from '../../lisentc'
import {getAuth, onAuthStateChanged} from 'firebase/auth'

const auth = getAuth(appFirebase)

function Navigation() {

  const [usuario, setUsuario] = useState(null)

  onAuthStateChanged(auth, (usuarioFirebase)=>{
      if (usuarioFirebase) {
          setUsuario(usuarioFirebase)
      }else{
          setUsuario(null)
      }

  })
  
    const location = useLocation();
  const [activeButton, setActiveButton] = useState("Música");

  useEffect(() => {
    if (location.pathname === "/music") {
      setActiveButton("Música");
    } else if (location.pathname === "/colaborar") {
      setActiveButton("Colaborar");
    } else if (location.pathname === "/home/live") {
      setActiveButton("Soporte");
    }
  }, [location.pathname]);


    const [isLanguageListOpen, setLangList] = useState(false);
    const [isOpenProfile, setOpenProfile] = useState(false);


    function handleOpenLanguageList() {
        if (isOpenProfile === true)
            setOpenProfile(!isOpenProfile);
        setLangList(!isLanguageListOpen);
    }

    function handleOpenProfile() {
        if (isLanguageListOpen === true)
            setLangList(!isLanguageListOpen);
        setOpenProfile(!isOpenProfile);
    }

    return (
        <nav>
            <Brand/>
            <div className={"navigation"}>
               <NavigationButton href={"/music"} name={"Música"} isActive={activeButton === "Música"}/>
               <NavigationButton href={"/colaborar"} name={"Colaborar"} isActive={activeButton === "Colaborar"}/>
               <a onClick={() => window.open("https://twitter.com/LisentSupport", "_blank")}>
                <NavigationButton href={"#"} name={"Soporte"} isActive={activeButton === "Soporte"}/>
                </a>
            </div>
            <SearchBar/>
            <div className={"notificaciones"} onClick={handleOpenLanguageList}>
                <Button className={"Dropdown-btn"}>
                    <div className="wrapper">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                    <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zm.995-14.901a1 1 0 1 0-1.99 0A5.002 5.002 0 0 0 3 6c0 1.098-.5 6-2 7h14c-1.5-1-2-5.902-2-7 0-2.42-1.72-4.44-4.005-4.901z"/>
                  </svg>

                    </div>
                </Button>
                {
                    isLanguageListOpen
                    &&
                    <DropDownLanguageList/>
                }
            </div>
            <div className="profile" onClick={handleOpenProfile}>
                <Link to="/music/perfil">
                <Button className={"Dropdown-btn"}
                style={{color: '#FFFFFF', height:'10px', padding:'18px', borderRadius: '5px', backgroundColor: '#32323D'}}>
                   {usuario ? `Perfil` :  `Acceder`}
                </Button>
                </Link>
            </div>
        </nav>
    );
}

export default Navigation;