import React from "react";

export const themes = {
    dark: {
        theme:"#ffffff",
        subTheme: "#0f0f15",
        component:{
            backgroundColor: "#18181D",
            color: "#fff",
        },
        volume:{
            color:"#07689f"
        }
    }
};

export const ThemeContext = React.createContext(
    themes.dark
);