import React from 'react';
import '../assets/scss/Playlist.scss';
import Container from "./Container";
import FiltrosActividades from "../fragment/FiltrosActividades";
import {useSelector} from "react-redux";
import MusicCard from "./MusicCard";
import { useLocation } from 'react-router-dom';

const Playlist = () => {
    const location = useLocation();
    const typeOfPlaylist = location.pathname.split('/').pop();
    const {playlists} = useSelector(state=>state.musicReducer);
    return (
        <Container>
            <div>
            <div className="actividades-contenedor">
             <h1 className="actividades-titulo">Cada actividad,<br/>tiene su estilo.</h1>
        

        <FiltrosActividades/>

        </div>


            <div  className={"Playlist"}>
                <h3>Según la categoría de {typeOfPlaylist}</h3>
                <div className="Playlist-container">
                    {
                        playlists.map((item)=>(
                            item.type && item.type.includes(typeOfPlaylist) &&
                            <MusicCard key={item.id} music={item}/>
                        ))
                    }
                </div>
            </div>
            </div>
            
            
        </Container>
    );
}

export default Playlist;